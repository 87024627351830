.simple-react-notifier {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  pointer-events: none;
  padding: 16px;
}

.simple-react-notifier > * {
  pointer-events: initial;
  animation-fill-mode: forwards;
}

.simple-react-notifier.top-left {
  left: 0;
  right: auto;
}

.simple-react-notifier.top-center {
  left: 50%;
  transform: translate(-50%, 0);
  right: auto;
}

.simple-react-notifier.bottom-left {
  top: auto;
  right: auto;
  left: 0;
  bottom: 0;
}

.simple-react-notifier.bottom-center {
  top: auto;
  left: 50%;
  transform: translate(-50%, 0);
  right: auto;
  bottom: 0;
}

.simple-react-notifier.bottom-right {
  top: auto;
  bottom: 0;
}

.simple-react-notifier .item {
  position: relative;
  min-height: 48px;
  margin-bottom: 16px;
  padding: 14px 6px;
  border-radius: 4px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  cursor: default;
  font-size: 14px;
  line-height: 1.3;
  color: white;
}

.simple-react-notifier .item span {
  margin: 0 20px 0 10px;
}

.simple-react-notifier .item button {
  cursor: pointer;
  color: white;
  background: transparent;
  border: 0;
  position: relative;
  top: -5px;
}

.simple-react-notifier .item.success {
  background: #28a745;
  border-left: 8px solid #1e7532;
}

.simple-react-notifier .item.info {
  background: #077bf7;
  border-left: 8px solid #055fbe;
}

.simple-react-notifier .item.error {
  background: #e23849;
  border-left: 8px solid #ac1f2d;
}

.simple-react-notifier .item.rtl {
  direction: rtl;
  border-left: 0;
}

.simple-react-notifier .item.rtl.success {
  border-right: 8px solid #1e7532;
}

.simple-react-notifier .item.rtl.info {
  border-right: 8px solid #055fbe;
}

.simple-react-notifier .item.rtl.error {
  border-right: 8px solid #ac1f2d;
}
